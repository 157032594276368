<template>
  <div class="auth-flash-message-container">
    <transition-group name="fade" tag="div">
      <div
        class="auth-flash-message has-background-white"
        v-for="message in messages"
        :class="message.level"
        :key="message.id"
      >
        <div>
          <img
            v-if="message.level === 'success'"
            v-lazy="`$/images/account/done.svg`"
            alt="Confirmed"
          />
          <img
            v-if="message.level === 'danger'"
            v-lazy="`$/images/account/error.svg`"
            alt="Confirmed"
          />
        </div>
        <span>
          {{ message.text }}
        </span>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    messages: Array
  }
}
</script>
<style lang="sass" scoped>
.auth-flash-message-container
  position: fixed
  right: 1px
  left: 1px
  bottom: 0
  z-index: 1
.success
  border: 1px solid #589F5A
  color: #589F5A
.danger
  border: 1px solid #FF3223
  color: #363636
.auth-flash-message
  display: flex
  justify-content: center
  align-items: center
  padding: 0 22px
  height: 50px
  > div
    display: flex
    align-items: center
    margin: 0 5px
    img
      margin: 0 5px
      min-width: 20px
      min-height: 20px
@media (min-width: 768px)
  .auth-flash-message-container
    position: absolute
    top: 4px
    left: 0
    right: 0
    bottom: inherit
    width: 464px
    margin: 0 auto
  .auth-flash-message
    border-radius: 4px
</style>

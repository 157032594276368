<template>
  <main-layout>

    <page-loading v-if="pageLoadingShown"></page-loading>
    <main
      v-show="!pageLoadingShown"
      class="section is-paddingless"
    >
      <div class="container auth-container" id="consent">
          <div :class="{'decorated': decorated, 'has-notification': true}">
            <notification-bar v-bind:messages="messages"></notification-bar>
            <div
              :id="consentCheckHandler ? 'consent-outer' : 'flow-inner'"
            >
              <router-view @loading="setLoading" @has-notification="setNotification"></router-view>
            </div>
          </div>

      </div>
    </main>
  </main-layout>
</template>

<script>
import PageLoading from '../partials/PageLoading'
import NotificationBar from './components/NotificationBar'

export default {
  components: { PageLoading, NotificationBar },

  data: () => ({
    pageLoadingShown: false,
    hasNotification: false,
    messages: []
  }),

  computed: {
    success () {
      return this.$route.path.match(/success\/?$/)
    },

    consentCheckHandler () {
      return this.$route.path.match(/consent\/?$/)
    },

    decorated () {
      return this.$route.path.match(/(join|login)\/?$/)
    }
  },

  methods: {
    setLoading (status) {
      this.pageLoadingShown = status
    },

    setNotification (status) {
      this.messages = this.$messages.all('auth')
      this.hasNotification = status
      setTimeout(() => {
        this.messages = this.$messages.removeShown()
        this.hasNotification = !status
      }, 10000)
    }
  }
}
</script>

<style lang="sass">

div#consent-outer
  box-shadow: 0px 0px 4px 2px rgba(204,200,200,0.40);

@media (max-width: 920px)
  .auth-container
    margin: 0 1.7rem;

@media (max-width: 540px)

  div#consent-outer
    margin: 1.25rem auto
    padding: 0 0 2.2rem 0
    max-width: 360px

@media (max-width: 320px)

  div#consent-outer
    margin: 1.25rem auto
    padding: 0 0 1.5rem 0
    max-width: 300px

@media (min-width: 541px)

  div#consent-outer
    background: #FFFFFF
    border-radius: 4px
    padding: 2rem
    width: 100%
    max-width: 700px
    margin: 2rem auto

  div#flow-wrapper
    padding: 1.25rem 0 2.25rem

@media (min-width: 768px)
  .has-notification div#flow-inner
    margin: 1rem auto
  div#flow-wrapper.decorated
    background: url(/static/images/auth_background.svg) no-repeat center 0
    padding-bottom: 8rem

div#success-inner

  & figure
    background: url(/static/images/auth_user_joined.svg) no-repeat center

  & p
    font-size: 0.9rem
    padding: 0 2rem
.container
  position: relative

.has-notification div#flow-inner
  position: relative

</style>
